'use client'

import { useTranslations } from 'next-intl'
import type { ReactElement, ReactNode } from 'react'

import { Box, ContentText, IconEmail, IconPhone, Section, spacing } from '@fortum/elemental-ui'

import unplugged from '@/images/unplugged.svg'
import { countryConfig } from '@/shared/countryConfig'
import { useTheme } from '@/shared/hooks/useTheme'

import { Icon } from '../Icon'
import { StyledHeading } from '../StyledHeading'
import { StyledLink } from '../StyledLink'
import { StyledImageErrorPage } from './StyledImageErrorPage'

const { phone, formattedPhone, email } = countryConfig.support

export type ErrorPageProps = {
  title?: string
  content?: string | ReactElement | ReactNode
  message?: string
  digest?: string
  hideHomeLink?: boolean
}

/**
 * Custom error page for unexpected, or expected errors.
 */
export const ErrorPage = ({ title, content, message, digest, hideHomeLink }: ErrorPageProps) => {
  const t = useTranslations('errorPage')
  const theme = useTheme()

  return (
    <Section textAlign="center">
      <StyledHeading level={1}>{title || t('title')}</StyledHeading>
      <Box data-testid="error-page-box-image" mv={{ default: spacing.xs, l: spacing.m }}>
        <StyledImageErrorPage src={unplugged} alt={t('imageAltText')} />
      </Box>
      <ContentText paragraph size="l" mb={{ default: spacing.xs, l: spacing.m }}>
        {content || t('content')}
      </ContentText>
      {!hideHomeLink && (
        <ContentText mb={{ default: spacing.xs, l: spacing.m }}>
          <StyledLink href="/" size="l" prefetch={false}>
            {t('homePageLink')}
          </StyledLink>
        </ContentText>
      )}
      <ContentText weight="bold" paragraph>
        {t('contactText')}
      </ContentText>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ default: 'column', s: 'row' }}
        justifyContent="center"
        gap={`${spacing.xxs}`}
      >
        <Box display="flex" alignItems="center">
          <Icon icon={IconPhone} mr={spacing.xxxs} />
          <StyledLink color={theme.colors.textPrimary} noUnderline href={`tel:${phone}`}>
            {formattedPhone}
          </StyledLink>
        </Box>
        {email && (
          <Box display="flex" alignItems="center">
            <Icon icon={IconEmail} mr={spacing.xxxs} />
            <StyledLink color={theme.colors.textPrimary} noUnderline href={`mailto:${email}`}>
              {email}
            </StyledLink>
          </Box>
        )}
      </Box>
      <ContentText tag="div" size="xs" color={theme.colors.textSecondary}>
        {message && <div>Message: {message}</div>}
        {digest && <div>Digest: {digest}</div>}
      </ContentText>
    </Section>
  )
}
