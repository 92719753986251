'use client'

import Image from 'next/image'
import styled from 'styled-components'

import { styles } from '@fortum/elemental-ui'

/**
 * Image style for error page illustration
 */

export const StyledImageErrorPage = styled(Image)`
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;

  ${styles.breakpoint.s} {
    width: 33.33%;
  }
`
